import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-6d93cdf2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "topbutton"
};
import { ref, reactive, toRefs } from 'vue';
import { Edit, Refresh, Plus, Delete } from '@element-plus/icons-vue';
import modalNewlyAdmin from './modal-newlyAdmin.vue'; // 新增/修改

export default {
  __name: 'Administrator',

  setup(__props) {
    const tableData = [{
      id: 1,
      pid: 0,
      name: '超级管理组',
      rules: '超级管理员',
      create_time: '2024-08-07 16:24:02',
      update_time: 1647805864,
      status: '1',
      ID: '111',
      mailbox: '11111111@qq.com'
    }];
    const state = reactive({
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      squareUrl: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
    });
    const {
      squareUrl
    } = toRefs(state); // 打开新增弹窗

    const modalNewlyAdminRef = ref();

    const openModelNewlyAdmin = record => {
      console.log(record);
      const datainfo = {
        titletext: record
      };
      modalNewlyAdminRef.value.showModal(datainfo);
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_table = _resolveComponent("el-table");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
        content: "刷新",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          color: "#626aef",
          icon: _unref(Refresh)
        }, null, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "添加",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Plus),
          color: "#626aef",
          onClick: _cache[0] || (_cache[0] = $event => openModelNewlyAdmin('添加'))
        }, {
          default: _withCtx(() => [_createTextVNode("添加")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "编辑",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Edit),
          color: "#626aef",
          onClick: _cache[1] || (_cache[1] = $event => openModelNewlyAdmin('编辑'))
        }, {
          default: _withCtx(() => [_createTextVNode("编辑")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      }), _createVNode(_component_el_tooltip, {
        content: "删除",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          icon: _unref(Delete),
          color: "red"
        }, {
          default: _withCtx(() => [_createTextVNode("删除")]),
          _: 1
        }, 8, ["icon"])]),
        _: 1
      })]), _createVNode(_component_el_table, {
        data: tableData,
        style: {
          "width": "100%",
          "margin-bottom": "20px"
        },
        "row-key": "id",
        border: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "50"
        }), _createVNode(_component_el_table_column, {
          label: "ID",
          prop: "ID"
        }), _createVNode(_component_el_table_column, {
          label: "用户名",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "昵称",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "头像"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_avatar, {
            shape: "square",
            size: 50,
            src: _unref(squareUrl)
          }, null, 8, ["src"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "邮箱",
          prop: "mailbox"
        }), _createVNode(_component_el_table_column, {
          label: "邮箱",
          prop: "mailbox"
        }), _createVNode(_component_el_table_column, {
          label: "最后登录",
          prop: "create_time"
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          prop: "create_time"
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          prop: "status"
        }), _createVNode(_component_el_table_column, {
          label: "操作"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            content: "编辑",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              onClick: _cache[2] || (_cache[2] = $event => openModelNewlyAdmin('编辑')),
              color: "#626aef",
              icon: _unref(Edit)
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(modalNewlyAdmin, {
        ref_key: "modalNewlyAdminRef",
        ref: modalNewlyAdminRef
      }, null, 512)], 64);
    };
  }

};